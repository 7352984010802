import { Dispatch, SetStateAction } from 'react';

import BuyAitechListItem from '@components/buyAitech/buyAitechListItem';
import { BuyAitechListItemOption } from '@components/buyAitech/buyAitechListItem/types';
import ModalFooter from '@components/modals/ModalFooter';

//import WarningMessage from '@components/warningMessage';
import { BUY_AITECH_STEP, BUY_AITECH_STEP_TYPE } from '../../constants';
import {
  E_PAYMENT_METHOD_OPTION_VALUE,
  PAYMENT_METHOD_OPTIONS,
} from './constants';
import { useConnectWallet } from '@shared/hooks';
import { useAccount } from 'wagmi';

interface IProps {
  paymentMethod: BuyAitechListItemOption;
  setPaymentMethod: (paymentMethod: BuyAitechListItemOption) => void;
  setCurrentStep: Dispatch<SetStateAction<BUY_AITECH_STEP_TYPE>>;
  handleClose: () => void;
}

const PaymentMethodStep = ({
  paymentMethod,
  setPaymentMethod,
  setCurrentStep,
  handleClose,
}: IProps) => {

  const { handleConnect } = useConnectWallet();
  const { address } = useAccount();

  const handleNext = () => {
    if (!address) {
      handleConnect();
      return;
    }
    setCurrentStep(
      paymentMethod?.value === E_PAYMENT_METHOD_OPTION_VALUE.CARD
        ? BUY_AITECH_STEP.FIAT_LIST
        : BUY_AITECH_STEP.CRYPTO_LIST
    );
  };

  return (
    <div>
      <p className="font-large mb-4">Select method</p>
      <div className="mb-6">
        {PAYMENT_METHOD_OPTIONS.map((option) => (
          <BuyAitechListItem
            key={option.value}
            option={option}
            isSelected={paymentMethod?.value === option.value}
            setSelectedItem={setPaymentMethod}
          />
        ))}
      </div>
      {/*<WarningMessage
        message="Please note that the tokens used to purchase AITECH cannot be
          withdrawn, as the amount of AITECH will be automatically converted to
          USDAI and deposited into your account balance."
      />*/}
      <ModalFooter
        confirmButtonProps={{
          onClick: handleNext,
          text: 'Next',
          isDisabled: !paymentMethod,
        }}
        cancelButtonProps={{
          onClick: handleClose,
          text: 'Cancel',
        }}
        onClose={handleClose}
      />
    </div>
  );
};
export default PaymentMethodStep;
