
import { Socket, io } from 'socket.io-client';

import config from '@shared/config';

export const createUserDepositSocket = (accessToken?: string): Socket => {
  return io(config.userApiUrl, {
    autoConnect: false,
    auth: {
      token: accessToken ? `Bearer ${accessToken}` : '',
    },
    transports: ['websocket'],
  });
};