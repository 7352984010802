import Script from 'next/script';
import { Dispatch, SetStateAction, useState } from 'react';

import { ICurrencyAmounts, INowPaymentsData } from '@interfaces';

import { BuyAitechListItemOption } from '@components/buyAitech/buyAitechListItem/types';

import { BUY_AITECH_STEP, BUY_AITECH_STEP_TYPE } from './constants';
import {
  //BuyWithCryptoStep,
  BuyWithFiatStep,
} from './steps/buyWithCurrencyStep';
import CompleteFiatPaymentStep from './steps/completeFiatPaymentStep';
import CryptoListStep from './steps/cryptoListStep';
import CryptoPaymentSuccess from './steps/cryptoPaymentSuccess';
import FiatCurrencyListStep from './steps/fiatCurrencyListStep';
import PaymentMethodStep from './steps/paymentMethodStep';
import { IPaymentOptions } from './types';

interface IProps {
  currentStep: BUY_AITECH_STEP_TYPE;
  setCurrentStep: Dispatch<SetStateAction<BUY_AITECH_STEP_TYPE>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
}

const BuyAitechStep = ({
  currentStep,
  setCurrentStep,
  setIsLoading,
  handleClose,
}: IProps) => {
  const [paymentOptions, setPaymentOptions] = useState<IPaymentOptions>({});

  const [currencyAmounts, setCurrencyAmounts] = useState<ICurrencyAmounts>({
    youPayAmount: '',
    youGetAmount: '',
    usdaiAmount: '',
  });

  const [nowPaymentsData, setNowPaymentsData] = useState<INowPaymentsData>({
    address: '',
    qrCode: '',
  });

  const { paymentMethod, currency } = paymentOptions;
  const currencyCode = currency?.value;

  const setCurrencyItem = (currency: BuyAitechListItemOption) => {
    setPaymentOptions((prevState) => ({ ...prevState, currency }));
  };

  switch (currentStep) {
    case BUY_AITECH_STEP.PAYMENT_METHODS:
      return (
        <PaymentMethodStep
          paymentMethod={paymentMethod}
          setPaymentMethod={(paymentMethod) =>
            setPaymentOptions((prevState) => ({ ...prevState, paymentMethod }))
          }
          handleClose={handleClose}
          setCurrentStep={setCurrentStep}
        />
      );
    //fiat
    case BUY_AITECH_STEP.FIAT_LIST:
      return (
        <FiatCurrencyListStep
          setCurrentStep={setCurrentStep}
          setCurrencyItem={setCurrencyItem}
          currencyItem={currency}
        />
      );
    case BUY_AITECH_STEP.BUY_FIAT:
      return (
        <BuyWithFiatStep
          setCurrentStep={setCurrentStep}
          currencyCode={currencyCode!}
          setIsLoading={setIsLoading}
          setNowPaymentsData={setNowPaymentsData}
          setCurrencyAmounts={setCurrencyAmounts}
        />
      );
    case BUY_AITECH_STEP.COMPLETE_PAYMENT_FIAT:
      return (
        <CompleteFiatPaymentStep
          handleClose={handleClose}
          currencyAmounts={currencyAmounts}
          currencyCode={currencyCode!}
        />
      );

    //crypto
    case BUY_AITECH_STEP.CRYPTO_LIST:
      return (
        <CryptoListStep
          setCurrentStep={setCurrentStep}
          setCurrencyItem={setCurrencyItem}
          currencyItem={currency}
        />
      );
    case BUY_AITECH_STEP.BUY_CRYPTO:
      return (
        <div className="scrollbarHide h-full min-h-[370px] flex-grow overflow-y-auto">
          <iframe
            title="Changenow exchange"
            id="iframe-widget"
            src={`https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=false&amount=100&amountFiat=1500&backgroundColor=1A1A1A&darkMode=true&from=${currencyCode?.toLowerCase()}&fromFiat=usd&horizontal=false&isFiat=false&lang=en-US&link_id=3a28a8d5f77a13&locales=false&logo=false&primaryColor=33FF85&to=aitech&toFiat=aitech&toTheMoon=true&logo=true`}
            style={{ height: '100%', width: '100%', border: 'none' }}
          ></iframe>
          <Script src="https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js" />
        </div>
        /*<BuyWithCryptoStep
          setCurrentStep={setCurrentStep}
          currencyCode={currencyCode!}
          setIsLoading={setIsLoading}
          setNowPaymentsData={setNowPaymentsData}
          setCurrencyAmounts={setCurrencyAmounts}
        />*/
      );
    case BUY_AITECH_STEP.SUCCESS_CRYPTO:
      return (
        <CryptoPaymentSuccess
          handleClose={handleClose}
          currencyAmounts={currencyAmounts}
          currencyCode={currencyCode!}
          nowPaymentsData={nowPaymentsData}
        />
      );
    default:
      null;
  }
};
export default BuyAitechStep;
