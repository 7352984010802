import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import { Socket } from 'socket.io-client';

import { COOKIE_NAME } from '@shared/constants/cookies';
import { createUserDepositSocket } from './userDepositSocket';


const useAuthenticatedUserDepositSocket = () => {
  const accessToken = Cookies.get(COOKIE_NAME.ACCESS_TOKEN);
  const [userSocket, setUserSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = createUserDepositSocket(accessToken);
    !!accessToken ? newSocket.connect() : newSocket.disconnect();

    setUserSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [accessToken]);

  return { socket: userSocket };
};
export default useAuthenticatedUserDepositSocket;
