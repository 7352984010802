import { useCallback, useEffect } from 'react';

import useAuthenticatedUserDepositSocket from '../useAuthenticatedUserDepositSocket';
import { IDepositSocketResponse } from '@interfaces';
import { toast } from 'react-toastify';
import { PROFILE_KEYS } from '@shared/constants';
import { useQueryClient } from '@tanstack/react-query';
import { useDepositModal } from '@features/profile/layout/profileStats/depositFundsModal/provider/context';

const useReadUserDepositEvent = () => {

  const { socket } = useAuthenticatedUserDepositSocket();

  const queryClient = useQueryClient();

  const { triggerSuccessModal } = useDepositModal();

  const handleDepositMessage = useCallback(
    (data: IDepositSocketResponse) => {
      if (data.tx && data.aitechAmount && data.usdAIAmount) {
        toast.dismiss('deposit-funds');

        queryClient.invalidateQueries({
          queryKey: PROFILE_KEYS.PURCHASE_INFO,
        });
        triggerSuccessModal({
          depositAmount: Number(data.aitechAmount)
        })
      }
    },
    [queryClient, triggerSuccessModal]
  );

  useEffect(() => {
    socket?.on('deposited', handleDepositMessage);

    return () => {
      socket?.off('deposited', handleDepositMessage);
    };
  }, [socket, handleDepositMessage]);
};

export default useReadUserDepositEvent;
