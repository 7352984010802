import { twMerge } from 'tailwind-merge';

import { IndividualSearchProps } from './types';

const DesktopSearch = ({
  isSearchInputFocused,
  onChange,
  onSearch,
  searchIcon: SearchIcon,
  setIsSearchInputFocused,
  className,
  placeholder,
  value,
}: IndividualSearchProps) => {
  return (
    <div
      data-testid="search"
      className={twMerge(
        'nav-bar-icon relative size-full border-b border-neutral700 bg-neutral900 px-4 py-2.5',
        'focus-within:border-success900 focus-within:bg-neutral1000 focus:text-error hover:bg-neutral900',
        isSearchInputFocused && 'text-white',
        className
      )}
    >
      <input
        type="search"
        autoComplete="off"
        value={value}
        id="search-id"
        className="transitionColor size-full overflow-hidden text-ellipsis whitespace-nowrap bg-transparent font-medium placeholder-neutral400 caret-brandPrimary focus:outline-none focus:ring-0 md:block"
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => setIsSearchInputFocused(true)}
        onBlur={() => setIsSearchInputFocused(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch?.();
          }
        }}
      />
      <SearchIcon className="ml-2 size-6" onClick={() => onSearch?.()} />
    </div>
  );
};

export default DesktopSearch;
