import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import BuyAitechModal from '@features/marketplace/buyAitech/BuyAitechModal';
import useDisclosure from '@shared/hooks/useDisclosure';
import UserLoginAuth from '@features/user/userAuth/login/UserLoginAuth';

interface IProps {
  className?: string;
  buttonClassName?: string;
  isUserLogin?: boolean;
  isMobile?: boolean;
}

const BuyAitechButton = ({ className, buttonClassName, isUserLogin = false, isMobile = false }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isOpen: isOpenLogin, onOpen, onClose } = useDisclosure();

  return (
    <div className={className}>
      <div>
        <button
          className={twMerge(
            'animatedBorderButton font-small roundedBlTr md:flexCenter z-[1] h-10 w-fit shrink-0 cursor-pointer overflow-hidden px-3 font-bold uppercase text-white',
            !isMobile && 'relative',
            buttonClassName
          )}
          onClick={() => {
            if (isUserLogin) {
              setIsOpen(true);
              return;
            }
            onOpen(); // open login modal
          }}
        >
          Buy AITECH
        </button>
      </div>

      {isOpen && <BuyAitechModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      {
        isOpenLogin && (
          <UserLoginAuth isOpen={isOpenLogin} onClose={onClose} onOpen={onOpen} />
        )
      }
    </div>
  );
};

export default BuyAitechButton;
