import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { useGetPurchaseInfo } from '@api/user/profile/queries/usePurchaseInfoQuery';

import DesktopMenu from '@components/navigation/desktopMenu';
import MobileMenu from '@components/navigation/mobileMenu';
import MainNavigationItem from '@components/navigation/navigationItems/MainNavigationItem';
import MainNavigationItemWithSubMenuSidebar from '@components/navigation/navigationItems/MainNavigationItemWithSubMenuSidebar';
import { INavItem } from '@components/navigation/types';
import Search from '@components/search';

import BuyAitechButton from '@features/marketplace/buyAitech/BuyAitechButton';
import { useDepositModal } from '@features/profile/layout/profileStats/depositFundsModal/provider/context';
import { formatDisplayCurrency } from '@features/utils/numbers';

import { VaultIcon } from '@shared/assets';
import { useSearchQuery } from '@shared/hooks';

interface IProps {
  items: INavItem[];
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
  isLoggedIn?: boolean;
}

const MarketplaceMenuWrapper = ({
  items,
  breakpoint = 'md',
  className,
  isLoggedIn,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const { data: purchaseData } = useGetPurchaseInfo();
  const { triggerModal } = useDepositModal();

  const pathName = usePathname();

  const { searchQuery, setSearchQuery, handleSearch } = useSearchQuery();

  useEffect(() => {
    setIsOpen(false);
    setIsSubMenuOpen(false);
  }, [pathName]);

  const handleMobileMenuSearch = () => {
    setIsOpen(false);
    handleSearch();
  };

  return (
    <div
      className={twMerge(
        `flexCenter h-full w-max justify-center 2xl:px-4`,
        className
      )}
    >
      <DesktopMenu breakpoint={breakpoint}>
        {items.map((item) => (
          <MainNavigationItem key={item.id} item={item} pathname={pathName} />
        ))}
      </DesktopMenu>
      <MobileMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setIsSubMenuOpen={setIsSubMenuOpen}
        childrenContainerClassName="flex flex-col grow justify-between"
        responsiveClassName={`${breakpoint}:hidden`}
      >
        <div className="flex flex-col">
          <div className="m-4">
            <Search
              placeholder={'Search AI products by name or publisher'}
              className="h-16 w-full border border-neutral800 bg-neutral800"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              noMobile
              onSearch={handleMobileMenuSearch}
            />
          </div>
          {items.map((item) =>
            item.opensNewMenu ? (
              <MainNavigationItemWithSubMenuSidebar
                key={item.id}
                item={item}
                pathname={pathName}
                isSubMenuOpen={isSubMenuOpen}
                setIsSubMenuOpen={setIsSubMenuOpen}
                breakpoint={breakpoint}
              />
            ) : (
              <MainNavigationItem
                key={item.id}
                item={item}
                pathname={pathName}
                breakpoint={breakpoint}
              />
            )
          )}
        </div>
        <div className="flex flex-col gap-4 px-6 pb-6">
          {isLoggedIn && (
            <div className="flex gap-4 bg-neutral800 p-4">
              <VaultIcon className="w-8 text-yellow500" />
              <div className="flex flex-col gap-2">
                <span className="font-xsmall text-neutral200">
                  Account balance:
                </span>
                <span>
                  {formatDisplayCurrency(purchaseData?.data.balanceAvailable)}
                </span>
              </div>
            </div>
          )}
          <div>
            <BuyAitechButton buttonClassName="w-full mb-2 border border-neutral600" isUserLogin={isLoggedIn} isMobile />
            {isLoggedIn && (
              <button
                onClick={() => triggerModal()}
                className="label-small roundedBlTr h-10 w-full border border-neutral600 uppercase"
              >
                Deposit aitech
              </button>
            )}
          </div>
        </div>
      </MobileMenu>
    </div>
  );
};

export default MarketplaceMenuWrapper;
